<template>
  <dashboard-layout
    class="outcome-page"
    :locations-list="userLocations"
    :current-location="currentUserLocation"
    @location-change="locationChangeHandler"
  >
    <template #outer-content>
      <ui-tabs
        :list="userLocations"
        :preselected-tab-id="currentUserLocation ? currentUserLocation.id : null"
        @tab-change="locationChangeHandler"
      />
    </template>
    <template #side-content>
      <main-side-menu
        :item-list="menuItems"
        active-item-id="cameras"
      />
      <user-actives
        :units-list="userUnits"
      />
      <user-manager
        :manager-data="userManagerData"
      />
    </template>
    <template #main-content>
      <title-main
        class="outcome-page__title"
        :text="t('menuCameras')"
      >

      </title-main>
      <ui-tabs
        :list="outcomeTabs"
        :preselectedTabId="outcomeTabs[0].id"
        hasContent
        flat
      >

        <template #1></template>
        <template #2></template>
        <template #3></template>
        <template #4></template>
      </ui-tabs>

      <div class="video-container">
        <div class="video-box">
          <h2>
            Территория между банным комплексом и рестораном
          </h2>

          <div class="stub-video__frame-wrapper1" id="video1">
            <video
              :src="`${video1}?v=1.0.0`"
              loop
              autoplay
              muted
              class="video-player"
            ></video>
          </div>
          <h2>
            Банный чан «Бриллиант Сибири» и банный комплекс
          </h2>
          <div class="stub-video__frame-wrapper1" id="video2">
            <video
              :src="`${video2}?v=1.0.0`"
              loop
              autoplay
              muted
              playsinline
              class="video-player"
            ></video>
          </div>
          <h2>
            Коттеджи в отеле VIEW GA Forest SPA,4*
          </h2>

          <div class="stub-video__frame-wrapper1" id="video3">
            <video
              :src="`${video3}?v=1.0.0`"
              loop
              autoplay
              muted
              playsinline
              class="video-player"
            ></video>
          </div>
          <h2>
            СПА-комплекс с бассейном
          </h2>

          <div class="stub-video__frame-wrapper1" id="video4">
            <video
              :src="`${video4}?v=1.0.0`"
              loop
              autoplay
              class="video-player"
            ></video>
          </div>
          <h2>
            Территория возле пруда
          </h2>

          <div class="stub-video__frame-wrapper1" id="video5">
            <video
              :src="`${video5}?v=1.0.0`"
              loop
              autoplay
              muted
              playsinline
              class="video-player"
            ></video>
          </div>

        </div>

        <div>
          <div class="cameras-texts">
            <p><a  @click="scrollToVideo('video1')">Территория между банным комплексом и рестораном</a></p>
            <p><a  @click="scrollToVideo('video2')">Банный чан «Бриллиант Сибири» и банный комплекс</a></p>
            <p><a  @click="scrollToVideo('video3')">Коттеджи в отеле VIEW GA Forest SPA,4*</a></p>
            <p><a  @click="scrollToVideo('video4')">СПА-комплекс с бассейном</a></p>
            <p><a  @click="scrollToVideo('video5')">Территория возле пруда</a></p>
          </div>
        </div>
      </div>

    </template>
  </dashboard-layout>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { updateMoneySum } from '@/helpers/common';
import TitleMain from '@/ui-kit/TitleMain/TitleMain.vue';
import UiTabs from '@/ui-kit/UiTabs/UiTabs.vue';
import DashboardLayout from '@/layouts/DashboardLayout.vue';
import MainSideMenu from '@/components/MainSideMenu/MainSideMenu.vue';
import UserActives from '@/components/UserActives/UserActives.vue';
import UserManager from '@/components/UserManager/UserManager.vue';
import { OUTCOME_PAGE_POPUPS_DATA } from '@/constants/outcome';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import translates from '@/views/translates';
import video1 from '@/assets/videos/video5.mp4';
import video2 from '@/assets/videos/video1.mp4';
import video4 from '@/assets/videos/video3.mp4';
import video5 from '@/assets/videos/video4.mp4';
import video3 from '@/assets/videos/video2.mp4';

export default {
  name: 'OutcomePage',
  components: {
    TitleMain,
    MainSideMenu,
    DashboardLayout,
    UiTabs,
    // SecondaryButton,
    UserActives,
    // SummaryInfo,
    UserManager,
  },
  mounted() {
    window.scrollTo(0, 0); // Прокрутка к началу страницы при монтировании компонента
  },
  setup() {
    const store = useStore();
    const { t, mergeLocaleMessage } = useI18n();
    mergeLocaleMessage('en', translates.en);
    mergeLocaleMessage('ru', translates.ru);
    const outcomeState = store.state.outcome;
    const userState = store.state.user;
    const userManagerData = computed(() => userState.managerData);
    const menuItems = computed(() => userState.sideMenuItems);
    const outcomeTabs = ref([
      { id: 1, label: 'Камеры наблюдения' },
      // { id: 2, label: 'Камеры наблюдения' },
      // { id: 3, label: 'Аккаунт' },
      // { id: 4, label: 'Инвентаризация' },
    ]);
    const outcomePopupData = ref(OUTCOME_PAGE_POPUPS_DATA);
    const userUnits = computed(() => userState.units);
    const userLocations = computed(() => userState.locations
      .map((location) => ({ ...location, label: location.name, labelEn: location.nameEn })));
    const currentUserLocation = computed(() => userState.currentLocation);
    // const gopData = computed(() => (outcomeState.allData.gop
    // ? `${updateMoneySum(outcomeState.allData.gop)}\u202F₽`
    // : '0\u202F₽'));
    const gopData = computed(() => {
      if (outcomeState.allData.gop >= 0) {
        return (outcomeState.allData.gop
          ? `${updateMoneySum(outcomeState.allData.gop)}\u202F₽`
          : '0\u202F₽');
      }
      return (outcomeState.allData.gop
        ? `-${updateMoneySum(Math.abs(outcomeState.allData.gop))}\u202F₽`
        : '0\u202F₽');
    });
    const gopPercent = computed(() => `${outcomeState.allData.gopPercent}%`);
    const incomeTotal = computed(() => (outcomeState.allData.incomeTotal
      ? `${updateMoneySum(outcomeState.allData.incomeTotal)}\u202F₽`
      : '0\u202F₽'));
    const incomePercent = computed(() => `${outcomeState.allData.incomePercent}%`);
    const outcomeTotal = computed(() => (outcomeState.allData.outcomeTotal
      ? `${updateMoneySum(outcomeState.allData.outcomeTotal)}\u202F₽`
      : '0\u202F₽'));
    const outcomePercent = computed(() => `${outcomeState.allData.outcomePercent}%`);
    const incomesList = computed(() => outcomeState.incomesList);
    const outcomesList = computed(() => outcomeState.outcomesList);
    const initOutcomeData = async () => {
      store.dispatch('base/setCurrentPath', useRoute());
      await store.dispatch('user/initUsersLocations');
      await store.dispatch('user/initSideMenuItems');
      if (!userState.profileSettings.name) {
        await store.dispatch('user/getUserProfileSettings');
      }
      store.dispatch('outcome/setDefaultData');
    };

    /* const downloadHandler = () => {
      store.dispatch('outcome/downloadOutcomeTable');
    };
    */
    const locationChangeHandler = (id) => {
      store.dispatch('user/setCurrentLocation', id);
      initOutcomeData();
    };

    const getIncomeOutcomeByMonth = ({ month, year }) => {
      store.dispatch('outcome/setSelectedData', { year, month });
    };
    const scrollToVideo = (videoId) => {
      document.getElementById(videoId).scrollIntoView({ behavior: 'smooth' });
    };

    initOutcomeData();

    return {
      t,
      userUnits,
      menuItems,
      userLocations,
      currentUserLocation,
      outcomeTabs,
      gopData,
      gopPercent,
      incomeTotal,
      incomePercent,
      outcomeTotal,
      outcomePercent,
      incomesList,
      outcomesList,
      outcomePopupData,
      locationChangeHandler,
      // downloadHandler,
      getIncomeOutcomeByMonth,
      userManagerData,
      video1,
      video2,
      video3,
      video4,
      video5,
      scrollToVideo,
    };
  },
};
</script>

<style lang="scss">
.outcome-page {

  &__content-block {
    padding-right: 25px;
    display: flex;
    flex-direction: column;
  }

  &__controls {
    display: flex;
    margin-top: 1px;
    border-bottom: 1px solid $grey200;

    &-item {
      margin-left: 12px;

      &:first-child {
        margin-left: 0;
      }

      .secondary-button {
        text-transform: capitalize;
      }
      .popup-dropdown__button {
        text-transform: capitalize;
      }
    }
  }

  &__table {
    margin-top: 16px;
  }

  &__title {
    &-block {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;

      &-text-area {
        display: flex;
        flex-direction: column;
        max-width: 740px;
      }
    }
  }

  &__subtitle {
    margin-top: 8px;
    color:$grey800;
    font-size: $font-size-regular;
    line-height: 18px;
    font-weight: $font-weight-regular;
  }

  &__tabs-block {
    margin-top: 60px;

    .outcome-table {
      margin-top: 60px;
    }
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .outcome-page {
    &__table {
      margin-top: 24px;
    }

    &__amount-outcome {
      margin-top: 20px;
      .unit-income {
        padding: 0;

        &__info-wrapper {
          margin: 0;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .outcome-page {
    &__content {
      &-block {
        padding-right: 0;
      }
    }

    &__title {
      &-block {
        margin-top: 35px;
        flex-direction: column;
      }
    }

    &__title {
      height: auto;
    }

    &__controls {
      margin-top: 24px;
      border-bottom: none;
    }

    &__tabs-block {
      margin-top: 20px;

      .tabs__content-slot {
        .outcome-table {
          margin-top: 20px;
        }
      }
    }
  }
}

.video-container {
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  gap: 30px;
}
@media screen and (max-width: 900px) {
  .video-container {
    width: 100%;
    flex-direction: column;
  }
  .video-box {
    width: 100% !important;
  }
}
.video-container h2 {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #10253E;
}
.video-box {
  width: 60%;
  display: flex;
  flex-direction: column;
  column-gap: 30px;
}

.cameras-texts {
  width: 350px;
  padding: 20px;
  gap: 24px;
  border-radius: 4px;
  border: 1px solid #EFF2F6;

}

.cameras-texts p {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #6A7383;
}

.stub-video__frame-wrapper{
  margin-bottom: 20px;
}

.video-player {
  width: 100%;
  height: 100%;
}

.stub-video__frame-wrapper1{
  padding-top: 0 !important;
}
html {
  scroll-behavior: smooth;
}
.cameras-texts a {
  text-decoration: none;
  color: inherit;
}

.cameras-texts p:hover {
  opacity: 0.7;
  cursor: pointer;
}
</style>
